define("discourse/plugins/discourse-automation/discourse/routes/admin-plugins-discourse-automation-edit", ["exports", "@ember/object", "rsvp", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/discourse-automation/discourse/admin/models/discourse-automation-field"], function (_exports, _object, _rsvp, _ajax, _discourse, _discourseAutomationField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AutomationEdit = _exports.default = (_class = class AutomationEdit extends _discourse.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "controllerName", "admin-plugins-discourse-automation-edit");
    }
    model(params) {
      return (0, _rsvp.hash)({
        scriptables: this.store.findAll("discourse-automation-scriptable").then(result => result.content),
        triggerables: (0, _ajax.ajax)(`/admin/plugins/discourse-automation/triggerables.json?automation_id=${params.id}`).then(result => result ? result.triggerables : []),
        automation: this.store.find("discourse-automation-automation", params.id)
      });
    }
    _fieldsForTarget(automation, target) {
      return (automation[target].templates || []).map(template => {
        const jsonField = automation[target].fields.find(f => f.name === template.name && f.component === template.component);
        return _discourseAutomationField.default.create(template, target, jsonField);
      });
    }
    setupController(controller, model) {
      const automation = model.automation;
      controller.setProperties({
        model,
        error: null,
        automationForm: {
          name: automation.name,
          enabled: automation.enabled,
          trigger: automation.trigger?.id,
          script: automation.script?.id,
          fields: this._fieldsForTarget(automation, "script").concat(this._fieldsForTarget(automation, "trigger"))
        }
      });
    }
    refreshRoute() {
      return this.refresh();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "refreshRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRoute"), _class.prototype)), _class);
});