define("discourse/plugins/discourse-automation/discourse/initializers/discourse-automation", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse-common/lib/helpers"], function (_exports, _ajax, _ajaxError, _pluginApi, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let _lastCheckedByHandlers = {};
  function _handleLastCheckedByEvent(event) {
    (0, _ajax.ajax)(`/append-last-checked-by/${event.currentTarget.postId}`, {
      type: "PUT"
    }).catch(_ajaxError.popupAjaxError);
  }
  function _cleanUp() {
    Object.values(_lastCheckedByHandlers || {}).forEach(handler => {
      handler.removeEventListener("click", _handleLastCheckedByEvent);
    });
    _lastCheckedByHandlers = {};
  }
  function _initializeDiscourseAutomation(api) {
    _initializeGLobalUserNotices(api);
    if (api.getCurrentUser()) {
      api.decorateCookedElement(_decorateCheckedButton, {
        id: "discourse-automation"
      });
      api.cleanupStream(_cleanUp);
    }
  }
  function _decorateCheckedButton(element, postDecorator) {
    if (!postDecorator) {
      return;
    }
    const elems = element.querySelectorAll(".btn-checked");
    const postModel = postDecorator.getModel();
    Array.from(elems).forEach(elem => {
      const postId = postModel.id;
      elem.postId = postId;
      if (_lastCheckedByHandlers[postId]) {
        _lastCheckedByHandlers[postId].removeEventListener("click", _handleLastCheckedByEvent, false);
        delete _lastCheckedByHandlers[postId];
      }
      _lastCheckedByHandlers[postId] = elem;
      elem.addEventListener("click", _handleLastCheckedByEvent, false);
    });
  }
  function _initializeGLobalUserNotices(api) {
    const currentUser = api.getCurrentUser();
    (0, _helpers.makeArray)(currentUser?.global_notices).forEach(userGlobalNotice => {
      api.addGlobalNotice("", userGlobalNotice.identifier, {
        html: userGlobalNotice.notice,
        level: userGlobalNotice.level,
        dismissable: true,
        dismissDuration: moment.duration(1, "week"),
        onDismiss() {
          (0, _ajax.ajax)(`/user-global-notices/${userGlobalNotice.id}.json`, {
            type: "DELETE"
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    });
  }
  var _default = _exports.default = {
    name: "discourse-automation",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.24", _initializeDiscourseAutomation);
    }
  };
});