define("discourse/plugins/discourse-automation/discourse/admin/models/discourse-automation-automation", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ATTRIBUTES = ["name", "script", "fields", "trigger", "id"];
  class Automation extends _rest.default {
    updateProperties() {
      return {
        id: this.id,
        name: this.name,
        fields: this.fields,
        script: this.script.id,
        trigger: {
          id: this.trigger.id,
          name: this.trigger.name,
          metadata: this.trigger.metadata
        }
      };
    }
    createProperties() {
      return this.getProperties(ATTRIBUTES);
    }
  }
  _exports.default = Automation;
});