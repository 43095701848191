define("discourse/plugins/discourse-automation/discourse/components/fields/da-tags-field", ["exports", "@ember/helper", "select-kit/components/tag-chooser", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "@ember/template-factory", "@ember/component"], function (_exports, _helper, _tagChooser, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class TagsField extends _daBaseField.default {}
  _exports.default = TagsField;
  _class = TagsField;
  (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field tags-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <TagChooser
              @tags={{@field.metadata.value}}
              @options={{hash allowAny=false disabled=@field.isDisabled}}
            />
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "183K1CX1",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field tags-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@tags\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[28,[32,2],null,[[\"allowAny\",\"disabled\"],[false,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-tags-field.js",
    "scope": () => [_daFieldLabel.default, _tagChooser.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _class);
});