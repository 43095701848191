define("discourse/plugins/discourse-automation/discourse/helpers/format-enabled-automation", ["exports", "@ember/template", "discourse-common/lib/icon-library"], function (_exports, _template, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatEnabledAutomation;
  function formatEnabledAutomation(enabled, trigger) {
    if (enabled && trigger.id) {
      return (0, _template.htmlSafe)((0, _iconLibrary.iconHTML)("check", {
        class: "enabled-automation",
        title: "discourse_automation.models.automation.enabled.label"
      }));
    } else {
      return (0, _template.htmlSafe)((0, _iconLibrary.iconHTML)("times", {
        class: "disabled-automation",
        title: "discourse_automation.models.automation.disabled.label"
      }));
    }
  }
});