define("discourse/plugins/discourse-automation/discourse/lib/fabricators", ["exports", "discourse/plugins/discourse-automation/discourse/admin/models/discourse-automation-automation", "discourse/plugins/discourse-automation/discourse/admin/models/discourse-automation-field"], function (_exports, _discourseAutomationAutomation, _discourseAutomationField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  Fabricators are used to create fake data for testing purposes.
  The following fabricators are available in lib folder to allow
  styleguide to use them, and eventually to generate dummy data
  in a placeholder component. It should not be used for any other case.
  */

  let sequence = 0;
  function fieldFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const template = args.template || {};
    template.accepts_placeholders = args.accepts_placeholders ?? true;
    template.name = args.name ?? "name";
    template.component = args.component ?? "boolean";
    template.value = args.value ?? false;
    template.is_required = args.is_required ?? false;
    template.extra = args.extra ?? {};
    return _discourseAutomationField.default.create(template, args.target ?? "script");
  }
  function automationFabricator() {
    let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const automation = new _discourseAutomationAutomation.default();
    automation.id = args.id || sequence++;
    automation.trigger = {
      id: (sequence++).toString()
    };
    automation.script = {
      id: (sequence++).toString()
    };
    return automation;
  }
  var _default = _exports.default = {
    field: fieldFabricator,
    automation: automationFabricator
  };
});