define("discourse/plugins/discourse-automation/discourse/components/fields/da-user-field", ["exports", "@ember/helper", "@ember/object", "select-kit/components/user-chooser", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "@ember/template-factory", "@ember/component"], function (_exports, _helper, _object, _userChooser, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let UserField = _exports.default = (_class = (_class2 = class UserField extends _daBaseField.default {
    onChangeUsername(usernames1) {
      this.mutValue(usernames1[0]);
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field user-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <UserChooser
              @value={{@field.metadata.value}}
              @onChange={{this.onChangeUsername}}
              @options={{hash
                maximum=1
                excludeCurrentUser=false
                disabled=@field.isDisabled
              }}
            />
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "71I+LsOP",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field user-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"onChangeUsername\"]],[28,[32,2],null,[[\"maximum\",\"excludeCurrentUser\",\"disabled\"],[1,false,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-user-field.js",
    "scope": () => [_daFieldLabel.default, _userChooser.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "onChangeUsername", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeUsername"), _class.prototype)), _class);
});