define("discourse/plugins/discourse-automation/discourse/components/fields/da-boolean-field", ["exports", "@ember/component", "@ember/modifier", "@ember/object", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let BooleanField = _exports.default = (_class = (_class2 = class BooleanField extends _daBaseField.default {
    onInput(event1) {
      this.mutValue(event1.target.checked);
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field boolean-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <Input
              @type="checkbox"
              @checked={{@field.metadata.value}}
              {{on "input" this.onInput}}
              disabled={{@field.isDisabled}}
            />
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "OmU3M+za",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field boolean-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],[[16,\"disabled\",[30,2,[\"isDisabled\"]]],[4,[32,2],[\"input\",[30,0,[\"onInput\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,2,[\"metadata\",\"value\"]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-boolean-field.js",
    "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _daFieldDescription.default],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype)), _class);
});